
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bgSections{
  background: rgb(5,48,119);
  background: linear-gradient(180deg, rgba(5,48,119,1) 0%, rgba(0,0,0,1) 100%);
}

.letras3d {
  text-align: left;
  font-family: Arial Black, serif;
  font-weight: bold;
  text-shadow: 0 1px 0 #ddd, 0 1px 0 #ccc, 0 1px 0 #bbb, 0 2px 0 #aaa, 0 2px 0 #acacac, 0 6px 1px rgba(0,0,0,0.1), 0 0 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.3), 0 3px 3px rgba(0,0,0,0.2), 0 2px 5px rgba(0,0,0,0.25), 0 10px 5px rgba(0,0,0,0.2), 0 20px 20px rgba(0,0,0,0.15);
}